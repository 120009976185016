import React, { useState, useCallback, useEffect } from "react"
import { graphql } from "gatsby"
import { navigate } from "gatsby-link"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { useSwipeable } from "react-swipeable"
import { isMobile } from "react-device-detect"
import useWindowSize from "../hooks/useWindowDimensions"

import Seo from "../components/Seo"

import "../styles/global.scss"
import "../styles/pages/single-artwork.scss"

const SingleArtwork = ({ pageContext, data, location }) => {
  // console.log(pageContext.media.localFile)
  // console.log(data.wpPost.title)
  // console.log(data.wpPost.categories.nodes[0].name)
  // console.log(data.wpPost.title)

  // console.log(data.wpPost.project.specs)
  // console.log(data.wpPost.project.date)
  const dida_desktop = `${data.wpPost.categories.nodes[0].name}, ${data.wpPost.title}, ${data.wpPost.project.specs}, ${data.wpPost.project.date}`

  const keyNavigation = useCallback(
    event => {
      // console.log(event.keyCode)
      let dest = ""
      switch (event.keyCode) {
        case 39:
          //next
          dest = pageContext.next
          break
        case 37:
          //prev
          dest = pageContext.prev
          break
        case 27:
          //next
          dest = "/archive/"
          break
        default:
          dest = ""
          break
      }
      if (dest !== "") {
        navigate(dest)
      }
    },
    [pageContext.next, pageContext.prev]
  )

  useEffect(() => {
    document.addEventListener("keydown", keyNavigation, false)

    return () => {
      document.removeEventListener("keydown", keyNavigation, false)
    }
  })

  const { width } = useWindowSize()
  const handleClick = event => {
    if (event.clientX > width / 2) {
      navigate(pageContext.next)
    } else {
      navigate(pageContext.prev)
    }
  }
  const handleSwipe = useSwipeable({
    onSwiped: eventData => {
      let dest = ""
      switch (eventData.dir) {
        case "Left":
          // next
          // console.log("next")
          dest = pageContext.next
          break
        case "Right":
          // prev
          // console.log("prev")
          dest = pageContext.prev
          break
        default:
          dest = location.pathName
          break
      }
      navigate(dest)
    },
  })

  let isVertical = false
  if (
    pageContext.media.localFile.childImageSharp.gatsbyImageData.width >
    pageContext.media.localFile.childImageSharp.gatsbyImageData.height
  ) {
    isVertical = false
  } else {
    isVertical = true
  }

  const [isNavigatorVisible, setIsNavigatorVisible] = useState(true)
  const [mouseText, setMouseText] = useState("")
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
  const handleHover = ev => {
    setMousePosition({
      x: ev.clientX - 18,
      y: ev.clientY - 5,
    })
    if (ev.clientX > window.innerWidth / 2) {
      setMouseText("Next")
    } else {
      setMouseText("Prev")
    }

    // console.log(ev.clientX, window.innerWidth, mouseText)
  }

  return (
    <div className="single-artwork-page-container">
      <Seo title={pageContext.media.localFile.name} />
      <motion.div
        className="image-interactive-invisible-wrapper "
        onMouseMove={ev => handleHover(ev)}
        onClick={ev => {
          handleClick(ev)
        }}
        onKeyDown={ev => {
          keyNavigation(ev)
        }}
        {...handleSwipe}
        role="button"
        tabIndex={0}
      >
        <GatsbyImage
          className={`${isVertical ? "portrait" : "landscape"}`}
          image={getImage(pageContext.media.localFile)}
          alt=""
        />
      </motion.div>
      <div className="navigator">
        <span className="sans-size">
          {pageContext.current}/{pageContext.total}
        </span>

        <motion.div
          onHoverStart={() => setIsNavigatorVisible(false)}
          onHoverEnd={() => setIsNavigatorVisible(true)}
        >
          <Link className="sans-size" to="/archive/">
            close
          </Link>
        </motion.div>
      </div>
      <div className="dida gv-container">
        <span className="sans-size">{dida_desktop}</span>
      </div>
      {isNavigatorVisible && !isMobile && (
        <motion.div
          className="mouse-navigator sans-size"
          style={{ x: mousePosition.x + 16, y: mousePosition.y }}
        >
          <span>{mouseText}</span>
        </motion.div>
      )}
    </div>
  )
}

export default SingleArtwork

export const query = graphql`
  query ($postID: String!) {
    wpPost(id: { eq: $postID }) {
      title
      slug
      categories {
        nodes {
          slug
          name
        }
      }
      project {
        date(formatString: "Y")
        specs
        gallery {
          title
          altText
          description
          localFile {
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP, AUTO])
              internal {
                content
                description
                ignoreType
                mediaType
              }
            }
          }
        }
      }
    }
  }
`
